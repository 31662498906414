import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import BeforeFooter from "../../frontend/components/homeWidget/BeforeFooter";
import {
  handleGetQuickReport,
  handleGetTransaction,
  handlePostTransaction,
} from "../../services/shef";
import { useSelector } from "react-redux";

export const SalesStament = () => {
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const { authToken, userInfo } = useSelector((state) => state.user);
  const [sales, setSales] = useState(null);
  const [dateRange, setdateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [dishName, setDishName] = useState("");
  const [debouncedDishName, setDebouncedDishName] = useState("");
  const [totalEarning, setTotalEarning] = useState(0);

  const getTrasnsaction = async () => {
    try {
      // const res = await handleGetTransaction(
      const res = await handlePostTransaction(
        authToken,
        userInfo.id,
        dateRange.startDate,
        dateRange.endDate,
        debouncedDishName,
      );
      console.log(res.reports.original.data);
      let data = res.reports.original.reports
        ? res.reports.original.reports
        : res.reports.original.data;
      // var transformedOrders = data.flatMap((order) =>
      //   order.reports.map((report) => ({
      //     ...order,
      //     // ...report,
      //     // reports: undefined, // Remove the nested "reports" array
      //   }))
      // );
      // console.log(transformedOrders);
      setSales(data);
    } catch (error) {
      console.error("Error while fetching dishes \n", error);
    }
  };
  // Debounce effect for dishName
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDishName(dishName);
    }, 500); // Delay for debouncing

    return () => clearTimeout(handler); // Cleanup timeout
  }, [dishName]);

  useEffect(() => {
    // Function to call the API whenever any of the filter values change
    // const fetchFilteredData = async () => {
    //   try {
    //     const res = await handleGetQuickReport(
    //       authToken,
    //       dateRange.startDate,
    //       dateRange.endDate,
    //       debouncedDishName
    //     );
    //     setSales(res.reports.original.reports);
    //   } catch (error) {
    //     console.error("Error while fetching dishes \n", error);
    //   }
    // };

    // Call the API if any of the fields are changed
    if (dateRange.startDate || dateRange.endDate || debouncedDishName) {
      getTrasnsaction();
    } else {
      getTrasnsaction();
    }
  }, [dateRange, debouncedDishName]);
  useEffect(() => {
    getTrasnsaction();
  }, []);

  useEffect(() => {
    if (sales) {
      const uniqueOrders = Array.from(
        new Map(sales?.map((order) => [order.order_id, order])).values()
      );

      // Calculate the sum for a specific field
      const totalSaleAmount = uniqueOrders.reduce(
        (sum, order) => sum + order.total_amount,
        0
      );
      // console.log(totalSaleAmount);
      setTotalEarning(totalSaleAmount);
    }
  }, [sales]);

  return (
    <>
      <div className="">
        <Header />
        <div className="container mx-auto p-5">
          <div className="mt-6 p-5 bg-white rounded-xl border border-borderClr">
            <div>
              <h3 className="text-xl font-semibold leading-tight border-b pt-2 pb-3 mb-0">
                My Wallet
              </h3>
              <div className="pt-4 text-center">
                <h4 className="text-5xl font-bold mb-0">
                  {/* {sales?.reduce(
                    (acc, order) => acc + order.commission_received,
                    0
                  )} */}
                  {parseFloat(totalEarning) === NaN
                    ? 0
                    : parseFloat(totalEarning).toFixed(2)}
                </h4>
                <h5 className="text-lg font-semibold leading-tight mt-2 mb-0">
                  {/* WALLET BALANCE */}
                  YOUR EARNING
                </h5>
              </div>
            </div>
          </div>
          <div className="mt-6 p-5 bg-white rounded-xl border border-borderClr">
            {/* <div className="flex md:flex-row flex-col justify-between items-center gap-2 border-b pb-3">
              <h3 className="text-xl font-semibold leading-tight md:mb-0 mb-2">
                Wallet Transaction History
              </h3> */}

            {/* <div className="flex items-center gap-2">
                <button className="bg-primaryDark py-1 px-4 rounded-md text-white text-base font-semibold">
                  Recent Transactions
                </button>
                <button className="bg-primaryDark py-1 px-4 rounded-md text-white text-base font-semibold">
                  All Transactions
                </button>
              </div> */}
            {/* </div> */}
            <div className="flex md:flex-row flex-col justify-between items-center gap-2 border-b pb-3">
              <h3 className="text-xl font-semibold leading-tight md:mb-0 mb-2">
                Wallet Transaction History
              </h3>

              <div className="text-sm text-gray-500 mt-1">
                <span>Tax (10%): </span>
                {/* <span className="font-semibold text-gray-700">₹100</span> Example value for tax */}
              </div>
            </div>

            <div className="grid grid-cols-12 gap-3 mt-8 border-b pb-8">
              <h3 className="lg:col-span-2 md:col-span-6 col-span-12 text-xl font-semibold leading-tight pt-2 mb-3">
                Select Date
              </h3>

              <div className="lg:col-span-3 md:col-span-4 col-span-12">
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium mb-1"
                >
                  From Date
                </label>
                <input
                  id="startDate"
                  type="date"
                  className="w-full"
                  value={dateRange?.startDate || ""}
                  onChange={(e) => {
                    setdateRange({
                      ...dateRange,
                      startDate: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="lg:col-span-3 md:col-span-4 col-span-12">
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium mb-1"
                >
                  To Date
                </label>
                <input
                  id="endDate"
                  type="date"
                  className="w-full"
                  disabled={!dateRange?.startDate}
                  value={dateRange?.endDate || ""}
                  onChange={(e) => {
                    setdateRange({
                      ...dateRange,
                      endDate: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <label
                  htmlFor="searchDish"
                  className="block text-sm font-medium mb-1"
                >
                  Search by Dish Name
                </label>
                <div className="flex relative">
                  <input
                    id="searchDish"
                    className="w-full"
                    placeholder="Search by dish name"
                    value={dishName || ""}
                    onChange={(e) => {
                      setDishName(e.target.value);
                    }}
                  />
                  <div className="absolute right-2 top-[13px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                      fill="#323232"
                    >
                      <path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              {/* Clear Filters Button */}
              <div className="lg:col-span-12 md:col-span-12 col-span-12 text-right mt-4">
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                  onClick={() => {
                    setdateRange({ startDate: "", endDate: "" });
                    setDishName("");
                  }}
                >
                  Clear Filters
                </button>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="text-left w-full menuTable border-0">
                <thead>
                  <tr className="border-b">
                    <th className="w-[15%]">ORDER ID</th>
                    <th className="w-[15%]">CUSTOMER NAME</th>
                    <th className="w-[15%]">Order Date</th>
                    <th className="w-[15%]">Delivery Date</th>
                    {/* <th className="w-[15%]">Dish</th> */}
                    {/* <th className="w-[15%]">Sale Amount</th> */}
                    <th className="w-[15%]">Total Amount</th>
                    <th className="w-[15%]">Website Commission</th>
                    <th className="w-[15%]">Delivery Charges</th>
                    <th className="w-[15%]">Discount</th>
                    <th className="w-[15%]">Net Income</th>
                  </tr>
                </thead>
                <tbody>
                  {sales?.map((val, index) => (
                    <React.Fragment key={index}>
                      <tr className="border-b">
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight font-semibold">
                            {val.order_id}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            {val.user_name}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            {val.order_date}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            {val.delivery_time}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            {val.net_total_amount}
                          </h4>
                        </td>
                        {/* <td>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            {val.dish_name
                              ? val.dish_name
                              : val.dish_names.join(", ")}
                          </h4>
                        </td> */}
                        {/* <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {val.sale_amount}
                          </h4>
                        </td> */}
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {val.commission_received}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {val.delivery_charges}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {val.discount_price}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {val.total_amount}
                          </h4>
                        </td>
                        <td>
                          <button
                            className="text-blue-500 hover:text-blue-700"
                            onClick={() => toggleRow(index)}
                          >
                            {expandedRow === index ? "Show Less" : "Show More"}
                          </button>
                        </td>
                      </tr>
                      {expandedRow === index && (
                        <tr>
                          <td colSpan="10" className="p-4 bg-gray-50">
                            {/* Inner table for dish details */}
                            <table className="w-full text-sm text-gray-700">
                              <thead>
                                <tr className="border-b">
                                  <th className="w-1/3">Dish Name</th>
                                  <th className="w-1/3">Quantity</th>
                                  <th className="w-1/3">Sale Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {val.reports.map((report, reportIndex) => (
                                  <tr key={reportIndex} className="border-b">
                                    <td>{report.dish_name}</td>
                                    <td>{report.quantity}</td>
                                    <td>{report.sale_amount}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <BeforeFooter />
    </>
  );
};
export default SalesStament;
