import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import BeforeFooter from "../components/homeWidget/BeforeFooter";

export const About = () => {
  const pageBnrImg = {
    position: "relative",
    backgroundImage: `url('./media/frontend/img/about/blog/1920x700/banner-1.jpg')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  return (
    <>
      <Header />
      <section>
        <div className="pageBanr py-20" style={pageBnrImg}>
          <div className="container mx-auto relative z-[1] text-center">
            <h2 className="font-semibold text-3xl text-white uppercase text-secondary tracking-wider tracking-widest">
              About Us
            </h2>
            <ul className="inline-block text-white mb-0">
              <li className="inline-block text-lg">
                <Link to="/" className="!text-white hover:!text-primary">
                  Home
                </Link>
              </li>
              <li className="inline-block px-3">/</li>
              <li className="inline-block text-lg font-semibold text-primary">
                About Us
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="aboutus section-padding">
        <div className="container mx-auto lg:px-2 px-4">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 align-items-center">
            <div className="my-auto">
              <div className="history-title mb-md-40">
                <h1 className="font-semibold text-3xl uppercase text-secondary tracking-wider tracking-widest lg:text-start text-center">
                  A History Written for Shefs Cloud Explore : Our Story
                </h1>
                <div className="w-[60px] h-[2px] bg-primary my-6 lg:mx-0 mx-auto"></div>
                <p className="mb-4 text-lg text-headGray lg:text-start text-center">
                  Shefs Cloud is where culinary innovations meet technology.
                  With Shefs Cloud, chefs, culinary businesses, and food
                  enthusiasts can access advanced cloud-based solutions tailored
                  to their needs.{" "}
                </p>
                <p className="mb-4 text-lg text-headGray lg:text-start text-center">
                  We believe seamless, efficient, and intelligent digital
                  solutions are the way forward for the food industry. Using our
                  platform, businesses can simplify operations, improve
                  productivity, and focus on what matters most: creating
                  delicious food.{" "}
                </p>
                <p className="mb-4 text-lg text-headGray lg:text-start text-center">
                  With Shefs Cloud, we're revolutionizing the culinary landscape
                  through technology. By simplifying operations and reducing
                  complexity, we help businesses and individuals grow. Shefs
                  Cloud is here to help you succeed, whether you're a chef,
                  entrepreneur, or culinary enthusiast.
                </p>
              </div>
            </div>
            <div className="">
              <div className="grid grid-cols-2 gap-4">
                <div className="my-auto">
                  <div className="histry-img mb-xs-20">
                    <img
                      src="/media/frontend/img/about/blog/255x200/about-section-3.jpg"
                      className="img-fluid full-width"
                      alt="Histry"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  <div className="histry-img">
                    <img
                      src="/media/frontend/img/about/blog/255x200/about-section-1.jpg"
                      className="img-fluid full-width"
                      alt="Histry"
                    />
                  </div>
                  <div className="histry-img">
                    <img
                      src="/media/frontend/img/about/blog/255x200/about-section-2.jpg"
                      className="img-fluid full-width"
                      alt="Histry"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding how-it-works bg-light-theme">
        {/* section1  */}
        {/* <div className="container mx-auto lg:px-2 px-4">
          <div className="text-center mb-8"> */}
        {/* <h2 className="font-semibold text-3xl uppercase tracking-wider tracking-widest">
              What We Do{" "}
            </h2>
            <div className="w-[60px] h-[2px] bg-primary my-4 mx-auto"></div> */}
        {/* <h3 className="alexBrush text-3xl capitalize text-headGray"> */}
        {/* <p className="text-lg text-headGray">
             /// Our Process


              Shefs Cloud provides cutting-edge, cloud-based tools that allow you
              to manage and optimize your culinary operations effortlessly. Our
              services include:
            </p> */}
        {/* <p className="mb-4 text-lg text-headGray lg:text-start text-center">
              Shefs Cloud provides cutting-edge, cloud-based tools that allow you
              to manage and optimize your culinary operations effortlessly. Our
              services include:
            </p> */}
        {/* </div> */}

        {/* <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="flex">
              <div className="how-it-works-box arrow-1 flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img src="/media/frontend/img/001-search.png" alt="icon" />
                    <span className="number-box">01</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Recipe Management
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Store, organize, and scale your recipes with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="how-it-works-box arrow-2 flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/004-shopping-bag.png"
                      alt="icon"
                    />
                    <span className="number-box">02</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Inventory Management
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Keep track of ingredients, stock levels, and supplier
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="how-it-works-box arrow-1 flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/002-stopwatch.png"
                      alt="icon"
                    />
                    <span className="number-box">03</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Menu Planning
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Plan menus effectively to meet customer demands and reduce
                    food waste.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="how-it-works-box flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/003-placeholder.png"
                      alt="icon"
                    />
                    <span className="number-box">04</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Collaboration Tools
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Seamlessly share recipes and insights with your team or
                    network.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

        {/* <p className="mt-4 text-lg text-headGray">
            By harnessing the power of cloud technology, we ensure that your
            data is secure, accessible, and always up-to-date, enabling you to
            operate with confidence and precision.
          </p> */}
        {/* </div> */}

        {/* section2 */}
        <div className="container mx-auto lg:px-2 px-4">
          <div className="text-center mb-8">
            <h2 className="font-semibold text-3xl uppercase tracking-wider tracking-widest">
              Our Values
            </h2>
            <div className="w-[60px] h-[2px] bg-primary my-4 mx-auto"></div>
            {/* <h3 className="alexBrush text-3xl capitalize text-headGray"> */}
            <p className="text-lg text-headGray">
              At Shefs Cloud, we are guided by
            </p>
            {/* <p className="mb-4 text-lg text-headGray lg:text-start text-center">
              At Shefs Cloud, we are guided by:
            </p> */}
          </div>

          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="">
              <div className="how-it-works-box arrow-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img src="/media/frontend/img/001-search.png" alt="icon" />
                    <span className="number-box">01</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Innovation
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Continuously evolving to meet the ever-changing needs of the
                    culinary world.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="how-it-works-box arrow-2">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/004-shopping-bag.png"
                      alt="icon"
                    />
                    <span className="number-box">02</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Reliability
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Offering secure, scalable, and user-friendly solutions you
                    can depend on.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="how-it-works-box arrow-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/002-stopwatch.png"
                      alt="icon"
                    />
                    <span className="number-box">03</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Collaboration
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Building a community where culinary professionals can thrive
                    together.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="how-it-works-box">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/003-placeholder.png"
                      alt="icon"
                    />
                    <span className="number-box">04</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Passion
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Celebrating the art and science of food at every stage of
                    the journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className="mt-4 text-lg text-headGray">
            It all started with a desire to make the culinary world smarter and
            more connected. In order to manage recipes, inventory, and
            operations efficiently, our founders recognized the challenges chefs
            and food businesses face. Chefs can use Shefs Cloud to streamline
            workflows, unlock creativity, and scale their businesses by bridging
            the gap between technology and culinary arts.
          </p>
          <p className="mt-4 text-lg text-headGray">
            We're proud to help culinary professionals embrace the future of
            food with confidence and ease today.
          </p>
        </div>

        {/* section3 */}
        <div className="container mx-auto lg:px-2 px-4 mt-8">
          <div className="text-center mb-8 ">
            <h2 className="font-semibold text-3xl uppercase tracking-wider tracking-widest">
              Why Choose Us
            </h2>
            <div className="w-[60px] h-[2px] bg-primary my-4 mx-auto"></div>
            {/* <h3 className="alexBrush text-3xl capitalize text-headGray">
              At Shefs Cloud, we are guided by
            </h3> */}
            {/* <p className="mb-4 text-lg text-headGray lg:text-start text-center">
              At Shefs Cloud, we are guided by:
            </p> */}
          </div>

          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="flex">
              <div className="how-it-works-box arrow-1 flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img src="/media/frontend/img/001-search.png" alt="icon" />
                    <span className="number-box">01</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    User-Friendly Interface
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Designed for chefs and teams of all skill levels.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="how-it-works-box arrow-2 flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/004-shopping-bag.png"
                      alt="icon"
                    />
                    <span className="number-box">02</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Custom Solutions
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Tailored features to suit your unique needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="how-it-works-box arrow-1 flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/002-stopwatch.png"
                      alt="icon"
                    />
                    <span className="number-box">03</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Real-Time Accessibility
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Manage your operations anytime, anywhere.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="how-it-works-box flex-1">
                <div className="how-it-works-box-inner">
                  <div className="icon-box">
                    <img
                      src="/media/frontend/img/003-placeholder.png"
                      alt="icon"
                    />
                    <span className="number-box">04</span>
                  </div>
                  <h6 className="font-semibold text-xl uppercase !mb-2">
                    Ongoing Support
                  </h6>
                  <p className="mb-0 text-lg text-headGray">
                    Celebrating the art and science of food at every stage of
                    the journey.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <p className="mt-4 text-lg text-headGray">
            Come join us on our journey. Shefs Cloud is more than a platform;
            it's a community of food lovers, innovators, and dreamers. Let's
            simplify operations, inspire creativity, and change the culinary
            world together.
          </p>
          <p className="mt-4 text-lg text-headGray">
            Are you ready to take your culinary business to the next level? Try
            Shefs Cloud today!
          </p>
        </div>
      </section>
      <section className="our-blog restaurent-ad">
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="ad-img p-relative py-24">
                <div className="container mx-auto lg:px-2 px-4">
                  <div className="history-title relative z-10">
                    <h2 className="lg:text-start text-center font-semibold text-2xl text-white uppercase text-secondary tracking-wider tracking-widest">
                      Shefs Cloud
                    </h2>
                    <div className="w-[60px] h-[2px] bg-primary my-6 lg:mx-0 mx-auto"></div>
                    <h3 className="lg:text-start text-center font-semibold text-4xl text-white uppercase text-secondary tracking-wider tracking-widest">
                      Variety of Dishes
                    </h3>
                    <p className="lg:text-start text-center mb-6 text-lg text-white text-headGray">
                      Book a dish easily at the best price
                    </p>
                    <div className="lg:text-start text-center ">
                      <Link
                        to="/all-dish-detail"
                        className="rounded-md py-2 px-6 text-base tracking-wider uppercase font-semibold whitespace-nowrap bg-primary py-3 !text-white border border-transparent hover:border-primaryGreen hover:!text-green-400 "
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BeforeFooter />
    </>
  );
};
export default About;
