import React, { useEffect, useRef, useState } from "react";
import BeforeFooter from "../../frontend/components/homeWidget/BeforeFooter";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import {
  handleChefPostReview,
  handleChefReplyToReply,
  handleGetAllReview,
  handleGetAllReviewReplies,
} from "../../services/order";

const OrderReviewChat = () => {
  const { userInfo, authToken } = useSelector((state) => state.user);
  //   get all review of left side box
  const [reviews, setReviews] = useState(null);
  const [activeReviewContact, setActiveReviewContact] = useState("");
  const [messages, setMessages] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isReplyToReply, setIsReplyToReply] = useState(null);
  const chatContainerRef = useRef(null);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      try {
        if (!isReplyToReply) {
          const respons = await handleChefPostReview(
            authToken,
            activeReviewContact,
            newMessage
          );
        } else {
          const respons = await handleChefReplyToReply(
            authToken,
            activeReviewContact,
            isReplyToReply?.id,
            newMessage
          );
        }

        setMessages([
          ...messages,
          {
            user: { is_chef: userInfo.is_chef, is_admin: userInfo.is_admin },
            reply: newMessage,
          },
        ]);
        setNewMessage("");

        // toast.success("Reply Submitted");
        // onRequestClose();
      } catch (error) {
        console.log(
          "Something went wrong while reply review \n",
          error.message
        );
        // toast.error(error.message || "Something went wrong while Reply");
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await handleGetAllReview(authToken, userInfo?.id);
        console.log(response.data.original.data.data);
        setReviews(response.data.original.data.data);
      } catch (error) {
        const message = error.message;
        // if (message != "Request failed with status code 404") {
        //   toast.error(error);
        // } else if (message === "Request failed with status code 404") {
        //   toast.error("chef isn't found in this city");
        // }
      }
    })();
  }, []);

  const fetchReviewReplies = async (replyId) => {
    setActiveReviewContact(replyId);
    try {
      const response = await handleGetAllReviewReplies(authToken, replyId);
      setMessages(response.replies);
      // setReviews(response.data);
    } catch (error) {
      const message = error.message;
      console.log(message);
    }
  };

  useEffect(() => {
    const lastChefIdNullMessage = messages
      ?.filter((msg) => msg.chef_id === null)
      .pop();
    if (lastChefIdNullMessage) {
      setIsReplyToReply(lastChefIdNullMessage);
    }
    console.log(lastChefIdNullMessage);

    // Scroll to the bottom whenever messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-screen">
      <Header />

      {/* Inner Section */}
      <div className="flex h-screen bg-gray-100">
        {/* Left Sidebar */}
        <div className="w-1/4 bg-white border-r flex flex-col h-[60vh]">
          <div className="py-4 px-6 bg-[#ec2044] text-white font-semibold text-lg">
            Reviews
          </div>
          <div className="flex-grow p-4 space-y-4 overflow-y-auto">
            {reviews?.length > 0 ? (
              reviews?.map((val, index) => (
                <div
                  key={index}
                  className={`flex items-center space-x-3 p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                    activeReviewContact === val.id
                      ? "bg-[#ec2044] text-white shadow-lg scale-105" // Active review highlight with slight zoom
                      : "bg-gray-100 hover:bg-[#ec2044]/10 hover:shadow-md" // Normal hover effect
                  }`}
                  onClick={() => fetchReviewReplies(val.id)}
                >
                  <div className="w-full p-4 border bg-white rounded-lg">
                    <div className="font-semibold text-gray-800">
                      {`${val.user_menu.user.first_name} ${val.user_menu.user.last_name}`}
                    </div>
                    <div className="text-sm text-gray-500 break-words">
                      {val.review}
                    </div>
                    <div className="text-sm text-gray-600 font-semibold mt-2">
                      <span className="text-gray-700">Order No:</span>
                      <span className="text-[#ec2044] ml-1">
                        {val.order.order_code}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-gray-500 text-center mt-4">
                {reviews === null ? (
                  // Loader when reviews are null
                  <div className="flex justify-center items-center">
                    <svg
                      className="animate-spin h-8 w-8 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  "No reviews available"
                )}
              </div>
            )}
          </div>
        </div>

        {/* Chat Area */}
        <div className="flex flex-col w-3/4 bg-white">
          {activeReviewContact === "" && messages === null ? (
            // Initial state: show message icon
            <div className="flex items-center justify-center w-full h-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-32 h-32 text-gray-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 8.25v7.5A2.25 2.25 0 0119.5 18H4.5a2.25 2.25 0 01-2.25-2.25v-7.5A2.25 2.25 0 014.5 8.25H19.5A2.25 2.25 0 0121.75 8.25z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 8.25l8.25 5.25 8.25-5.25"
                />
              </svg>
            </div>
          ) : activeReviewContact !== "" && messages === null ? (
            // Loader during API call
            <div className="flex items-center justify-center w-full h-full">
              <svg
                className="animate-spin h-16 w-16 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                ></path>
              </svg>
            </div>
          ) : (
            // Show messages after API call
            <>
              <div className="py-4 px-6 bg-[#ec2044] text-white font-semibold text-lg">
                Replies
              </div>
              <div
                ref={chatContainerRef}
                className="flex-grow h-[500px] overflow-y-auto p-4 space-y-4 bg-gray-50"
              >
                {messages?.map((msg, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      msg.user.is_chef === 1 && msg.user.is_admin === 1
                        ? "justify-start"
                        : "justify-end"
                    }`}
                  >
                    <div
                      className={`max-w-lg px-4 py-2 rounded-lg shadow break-words ${
                        msg.user.is_chef === 1 && msg.user.is_admin === 1
                          ? "bg-blue-200 text-gray-800"
                          : "bg-[#ec2044] text-white"
                      }`}
                    >
                      {msg.reply}
                    </div>
                  </div>
                ))}
              </div>

              <div className="p-4 border-t bg-white flex items-center">
                <input
                  type="text"
                  placeholder="Type a message..."
                  className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#ec2044] focus:border-[#ec2044]"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                <button
                  className="ml-4 px-4 py-2 bg-[#ec2044] text-white rounded-lg hover:bg-[#ec2044]/90"
                  onClick={handleSendMessage}
                >
                  Send
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <BeforeFooter />
    </div>
  );
};

export default OrderReviewChat;
