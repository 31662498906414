import React, { useState, useRef, useEffect, useMemo } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import {
  handleShowProfile,
  handleUpdateAddress,
  handleUpdateProfile,
} from "../../../frontend/auth/Auth";
import { updateUser } from "../../../store/slice/user";
import { toast } from "react-toastify";
import isValidURL from "../../../ValidateUrl";
import MapWithConditionalRendering from "../../../frontend/components/Map/MapWithConditionRendering";
import { handleGetUserAddress } from "../../../services/shef";
import InputMask from "react-input-mask";

export const ProfileForm = () => {
  const dispatch = useDispatch();
  const libraries = useMemo(() => ["places"], []); // Array of required libraries
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [autocompleteRef, setAutocompleteRef] = useState(null); // Use state for the ref
  const [error, setError] = useState("");

  const [coordinates, setCoordinates] = useState({
    lat: "",
    lng: "",
  });
  const [isShowInitialAddress, setIsShowInitialAddress] = useState(true);
  const [mapLoading, setMapLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [profileData, setProfileData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    bio: "",
    profile_pic: "",
    cover_pic: "",
  });

  // contain the image file to be uploaded on server
  const [imageToUpload, setImageToUpload] = useState({
    profile: "",
    cover: "",
  });
  const [address, setAddress] = useState("");
  //    const [city, setCity] = useState('')
  //    const [country, setCountry] = useState('')
  //    const [zipCode, setZipCode] = useState('')

  const { authToken } = useSelector((state) => state.user);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries,
  });

  // Triger show-profile after updating profile
  const [shouldReload, setShouldReload] = useState(false);
  // fetch user - (show-profile)
  useEffect(() => {
    (async () => {
      const response = await handleShowProfile(authToken);
      // console.log("show profile data ", response);

      if (response) {
        localStorage.setItem("user", JSON.stringify(response)); //update user in local-storage
        dispatch(updateUser(response));
        setProfileData(response);
        if (response.chef_addresses.length > 0) {
          const { address, latitude, longitude } =
            response?.chef_addresses[response.chef_addresses.length - 1];

          setCoordinates({
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          });
          setAddress(address);
        } else {
          setAddress("");
        }
        // if (response.user_addresses.length > 0) {
        //   const { address } =
        //     response?.user_addresses[response.user_addresses.length - 1];
        //   setAddress(address);
        // } else {
        //   setAddress("");
        // }
      }
      // const {address} = filteredData.user_addresses[filteredData.user_addresses.length-1];
      // setProfileData(filteredData)
      // localStorage.setItem("user", JSON.stringify(filteredData)); //update user in local-storage
      // dispatch(updateUser(filteredData))                          // to instantly update user in redux-store.
    })();
  }, [authToken, dispatch, shouldReload]);


  useEffect(() => {
    if (coordinates?.lat !== "") {
      // console.log(coordinates);
      setTimeout(() => {
        setMapLoading(false);
      }, 1000);

      // based on users longitude and latitude get its address
      const getUsersAddress = async () => {
        try {
          const result = await handleGetUserAddress(
            coordinates?.lat,
            coordinates?.lng
          );
          // console.log("Users Address:", result);
          setAddress(result.formatted_address);
        } catch (error) {
          console.error("Error:", error.message);
        }
      };
      if(!isShowInitialAddress){
        getUsersAddress();
      }
      }
  }, [coordinates]);

  // On Change handler - profile data
  const handleProfileDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const phoneRegex = /^03\d{9}$/;
      if (!phoneRegex.test(value)) {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      } else {
        setError("");
      }
      // Update the credentials with the formatted phone number
      setProfileData({ ...profileData, [name]: value });
    } else {
      setProfileData({ ...profileData, [name]: value });
    }
  };

  // Update profile - ON SUBMIT
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!error) {
      setIsPending(true);
      try {
        e.preventDefault();
        // --- Update User detail - Form-data
        const formData = new FormData();
        formData.append("id", profileData.id);
        formData.append("first_name", profileData.first_name);
        formData.append("last_name", profileData.last_name);
        formData.append("email", profileData.email);
        formData.append("phone", profileData.phone);
        formData.append("bio", profileData.bio);
        console.log(imageToUpload);
        // if (imageToUpload.profile) {
        formData.append(
          "profile_pic",
          imageToUpload.profile ? imageToUpload.profile : null
        );
        // }
        // if (imageToUpload.cover) {
        formData.append(
          "cover_pic",
          imageToUpload.cover ? imageToUpload.cover : null
        );
        // }

        //--- Update address - Form-data
        const addressFormData = new FormData();
        addressFormData.append("name", profileData.first_name);
        addressFormData.append("phone", profileData.phone);
        addressFormData.append("address", address);
        // not required for now
        // if(!coords.latitude){
        //     toast.error("Please Allow Location")
        //     return;
        // }
        addressFormData.append("longitude", coordinates.lng);
        addressFormData.append("latitude", coordinates.lat);

        //--- api calling
        const response = await handleUpdateProfile(authToken, formData);
        const addressResponse = await handleUpdateAddress(
          authToken,
          addressFormData
        );

        //--- Update address resposne handling
        setAddress(addressResponse.address);
        const { latitude, longitude } = addressResponse; // Destructure the co-ordinates
        if (latitude && longitude) {
          setCoordinates({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
        }
        // console.log(response);
        //--- Update User Profile response handling
        // const filteredData = Object.fromEntries(Object.entries(response).filter(([_, v]) => v != null)); // null values are removed
        // setProfileData(filteredData)                                // update current page
        // localStorage.setItem("user", JSON.stringify(filteredData)); //update user in local-storage
        // dispatch(updateUser(filteredData))
        toast.success("Profile Updated");
        setShouldReload((prev) => !prev);
        // window.location.reload();
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setIsPending(false);
      }
    }
  };
  // Image File
  const fileInputRef = useRef(null);
  const coverFileInputRef = useRef(null);

  // cover
  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageToUpload((prev) => ({
        ...prev,
        cover: file,
      }));
      const reader = new FileReader();
      reader.onload = () => {
        // Set the selected image in state
        setProfileData({ ...profileData, cover_pic: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCoverBoxClick = () => {
    if (coverFileInputRef.current) {
      coverFileInputRef.current.click();
    }
  };

  // profile
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageToUpload((prev) => ({
        ...prev,
        profile: file,
      }));
      const reader = new FileReader();
      reader.onload = () => {
        // Set the selected image in state
        setProfileData({ ...profileData, profile_pic: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleBoxClick = () => {
    // Trigger click on the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (!isLoaded) return <div></div>;

  const onDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setCoordinates({ lat, lng });
    setIsShowInitialAddress(false);
    // console.log("New Coordinates:", { lat, lng }); // This will log the updated coordinates
  };

  // let autocompleteRef = null;

  const handlePlaceSelect = () => {
    if (autocompleteRef) {
      const place = autocompleteRef.getPlace();
      if (place) {
        // console.log("Selected place:", place);
        setSelectedPlace(place); // Store the selected place object
        setAddress(place.formatted_address || ""); // Update the address input with formatted address
      }
    } else {
      console.error("Autocomplete instance is not available.");
    }
  };

  return (
    <>
      <div className="p-5 rounded-xl border border-borderClr mb-6">
        <h2 className="text-xl font-semibold border-b pb-2 mb-4">
          Profile Info
        </h2>
        <form onSubmit={handleOnSubmit}>
          {/* Image container  */}
          <div className="flex gap-4 flex-wrap flex-col">
            {/* Cover image */}
            <div className="">
              <div
                className="w-full h-[140px] sm:h-[200px] border border-borderClr rounded-lg overflow-hidden relative cursor-pointer"
                onClick={handleCoverBoxClick}
              >
                {/* If cover image exists and is a valid URL */}
                {profileData.cover_pic && isValidURL(profileData.cover_pic) ? (
                  <>
                    <img
                      src={profileData.cover_pic}
                      alt="Cover"
                      className="w-full h-full object-cover"
                    />
                    {/* Close Button */}
                    <button
                      className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs hover:bg-opacity-80"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering onClick of parent div
                        setProfileData({ ...profileData, cover_pic: "" }); // Clear the image
                        setImageToUpload({ ...imageToUpload, cover: "" });
                      }}
                    >
                      ✖
                    </button>
                  </>
                ) : (
                  <p className="text-center flex items-center justify-center text-sm text-[#777] h-full">
                    Click to add Cover
                  </p>
                )}
              </div>

              {/* Hidden file input */}
              <input
                type="file"
                accept="image/*"
                onChange={handleCoverImageChange}
                ref={coverFileInputRef}
                style={{ display: "none" }}
              />
              <h3 className="text-base font-semibold mb-1 uppercase mt-1">
                Upload Your Cover
              </h3>
            </div>

            {/* Profile */}
            <div className="">
              <div
                className="w-[170px] h-[190px] border border-borderClr rounded-lg overflow-hidden relative cursor-pointer"
                onClick={handleBoxClick}
              >
                {/* If profile image exists and is a valid URL */}
                {profileData.profile_pic &&
                isValidURL(profileData.profile_pic) ? (
                  <>
                    <img
                      src={profileData.profile_pic}
                      alt="Profile"
                      className="w-full h-full object-cover"
                    />
                    {/* Close Button */}
                    <button
                      className="absolute top-2 right-2 bg-black bg-opacity-50 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs hover:bg-opacity-80"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering parent click event
                        setProfileData({ ...profileData, profile_pic: "" }); // Clear the image
                        setImageToUpload({ ...imageToUpload, profile: "" });
                      }}
                    >
                      ✖
                    </button>
                  </>
                ) : (
                  <p className="text-center flex items-center justify-center text-sm text-[#777] h-full">
                    Click to add Profile
                  </p>
                )}
              </div>

              {/* Hidden file input */}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <h3 className="text-base font-semibold mb-1 uppercase mt-2">
                Upload Your Profile
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12 md:gap-x-8 gap-x-0 gap-y-4 mt-8">
            <div className="md:col-span-6 col-span-12">
              <h4 className="text-base font-semibold mb-1 uppercase">
                First Name <span className="text-primary">*</span>
              </h4>
              <input
                type="text"
                placeholder="Enter First Name"
                id=""
                name="first_name"
                onChange={handleProfileDataChange}
                value={profileData.first_name}
                required
              />
            </div>

            <div className="md:col-span-6 col-span-12">
              <h4 className="text-base font-semibold mb-1 uppercase">
                Last Name{" "}
              </h4>
              <input
                type="text"
                placeholder="Enter Last Name"
                id=""
                name="last_name"
                onChange={handleProfileDataChange}
                value={profileData.last_name}
                required
              />
            </div>

            <div className="md:col-span-6 col-span-12">
              <h4 className="text-base font-semibold mb-1 uppercase">
                Email <span className="text-primary">*</span>
              </h4>
              <input
                type="email"
                placeholder="Enter Email"
                id=""
                name="email"
                readOnly
                className="read-only:bg-gray-50 !border-headGray"
                // onChange={handleProfileDataChange}
                value={profileData.email}
                required
              />
            </div>

            <div className="md:col-span-6 col-span-12">
              <h4 className="text-base font-semibold mb-1 uppercase">
                Mobile Number <span className="text-primary">*</span>{" "}
              </h4>
              {/* <input
                type="text"
                placeholder="+92xxxxxxxxxx"
                id=""
                name="phone"
                onChange={handleProfileDataChange}
                value={profileData.phone}
                required
              /> */}
              <InputMask
                mask="03999999999" // Fixed +92, and allow the user to enter 10 digits after it
                value={profileData.phone}
                onChange={handleProfileDataChange}
                className="your-input-class" // Your custom class here
                placeholder="032xxxxxxxxx"
                required
                maskChar={"X"}
                alwaysShowMask={true}
              >
                {(inputProps) => (
                  <input {...inputProps} type="text" name="phone" required />
                )}
              </InputMask>
              {error && (
                <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                  {error}
                </div>
              )}
            </div>

            <div className="col-span-12">
              <h4 className="text-base font-semibold mb-1 uppercase">
                Address <span className="text-primary">*</span>{" "}
              </h4>
              <Autocomplete
                onLoad={(autocomplete) => setAutocompleteRef(autocomplete)} // Store reference in state
                onPlaceChanged={handlePlaceSelect} // Trigger on place change
              >
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter Address"
                />
              </Autocomplete>
            </div>

            {/* <div className='md:col-span-4 col-span-12'>
                            <h4 className='text-base font-semibold mb-1 uppercase'>City <span className='text-primary'>*</span></h4>
                            <Autocomplete> 
                                <input 
                                    type="text" name="city" 
                                    id="city" placeholder='Enter City' 
                                    value={city}
                                    onChange={(e)=>setCity(e.target.value)}
                                />
                            </Autocomplete>
                        </div>       */}

            {/* <div className='md:col-span-4 col-span-12'>
                            <h4 className='text-base font-semibold mb-1 uppercase'>Zip Code </h4>        
                            <input 
                                type="text" placeholder='Enter Zip Code' 
                                id='' name='zip_code'
                                value={zipCode}
                                onChange={(e)=>setZipCode(e.target.value)} 
                            />
                        </div>  */}

            {/* <div className='md:col-span-4 col-span-12'>
                            <h4 className='text-base font-semibold mb-1 uppercase'>Country <span className='text-primary'>*</span></h4>
                            <Autocomplete>
                                <input 
                                    type="text" name="country" 
                                    id="country" placeholder='Enter Country' 
                                    value={country}
                                    onChange={(e)=>setCountry(e.target.value)}
                                />
                            </Autocomplete>
                        </div> */}

            {/* Temporary added */}
            {/* <div className='md:col-span-6 col-span-12'>
                            <h4 className='text-base font-semibold mb-1 uppercase'>Longitude </h4>
                            <input 
                                type="number" 
                                placeholder='Enter Longitude' 
                                id='' 
                                name='longitude'
                                onChange={(e) => setCoords({ longitude: parseFloat(e.target.value) })} 
                                value={coords.longitude}
                            />
                        </div>
                        <div className='md:col-span-6 col-span-12'>
                            <h4 className='text-base font-semibold mb-1 uppercase'>Latitude </h4>
                            <input 
                                type="number" 
                                placeholder='Enter Longitude' 
                                id='' 
                                min={0} 
                                step={0.01}
                                name='latitude'
                                onChange={(e) => setCoords({ latitude: parseFloat(e.target.value) })} 
                                value={coords.latitude}
                            />
                        </div> */}
            {/* Temporary - end */}

            <div className="col-span-12">
              <h4 className="text-base font-semibold mb-1 uppercase">
                Bio {/*<span className='text-primary'>*</span>*/}{" "}
              </h4>
              <textarea
                name="bio"
                id="bio"
                className="h-[70px]"
                onChange={handleProfileDataChange}
                value={profileData.bio}
                placeholder="Enter Bio"
              />
            </div>
            <div className="col-span-12">
              {/* {coordinates?.lat == "" ? ( */}
              {mapLoading ? (
                <div>loafing . . . </div>
              ) : (
                <MapWithConditionalRendering
                  coordinates={{ lat: coordinates.lat, lng: coordinates.lng }}
                  // coordinates={{lat:12, lng:23}}
                  onDragEnd={onDragEnd}
                  showMap={true}
                />
              )}
            </div>
            <div className="md:col-span-6 mt-3 col-span-12  flex items-end">
              <button
                disabled={isPending || error}
                className="bg-primary text-white w-ful p-[4px_12px] rounded text-base font-semibold mb-1 uppercase disabled:opacity-60"
                type=""
              >
                {isPending ? (
                  <div className="flex items-center">
                    <svg
                      className="w-5 h-5 mr-2 animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                      ></path>
                    </svg>
                    Loading...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default ProfileForm;
