import React, { useState } from "react";
import { handleEmailSubscribe } from "../../auth/Auth";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const Subscriber = () => {
  const { authToken } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!emailError && email !== "") {
      setIsLoadingForm(true);
      try {
        e.preventDefault();
        toast.dismiss();

        const res = await handleEmailSubscribe(authToken, { email: email });

        setEmail("");
        toast.success(
          typeof res.message === "string"
            ? res.message
            : res.message.original.message || "Subbscribed Successfully "
        );
        setIsLoadingForm(false);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
        setIsLoadingForm(false);
      }
    }
  };
  return (
    <>
      <div className="subscribeBanner">
        <div className="container mx-auto lg:px-0 px-4">
          <div className="py-20 text-center">
            <h1 className="font-semibold md:text-5xl text-3xl uppercase text-white tracking-wider tracking-widest">
              To Get Regular Updates
            </h1>
            <div className="w-[80px] h-[2px] bg-primary mx-auto my-6"></div>
            <h3 className="alexBrush text-4xl text-headGray">Subscribe</h3>
            <form onSubmit={onSubmit}>
              <div className="lg:w-2/4 sm:w-1/2 w-full mx-auto">
                <div className="bnrSearchBar flex gap-x-2">
                  <input
                    type="email"
                    className="py-2 h-[45px] border border-headGray text-base focus:border-primary"
                    placeholder="Enter your Email"
                    required
                    value={email}
                    onChange={handleChange}
                  />

                  <button
                    disabled={emailError || isLoadingForm}
                    className="rounded-md py-2 px-4 text-base font-semibold whitespace-nowrap bg-primary text-white hover:text-green-400 uppercase tracking-wider"
                  >
                    {isLoadingForm ? (
                      <div className="flex items-center">
                        <svg
                          className="w-5 h-5 mr-2 animate-spin text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                          ></path>
                        </svg>
                        Loading...
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
                {/* Error warning */}
                {emailError && (
                  <div className="bg-[#ff1c1c31] mt-1 text-white py-1 px-1 text-[12px] font-semibold">
                    {emailError}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscriber;
