import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../../shef_dashboard/components/Header";
import BeforeFooter from "../../frontend/components/homeWidget/BeforeFooter";
import { useSelector } from "react-redux";
import { handleGetAllDishes, handleGetTransaction, handlePostTransaction } from "../../services/shef";
import isValidURL from "../../ValidateUrl";
import moment from "moment";
import {
  handleGetOrders,
  handleGetPendingOrdersForChef,
} from "../../services/order";

export const Dashboard = () => {
  const navigate = useNavigate()

  const [dishes, setDishes] = useState([]);
  const [sales, setSales] = useState({});

  // initially we are are getting month start till month today date
  const [dateRange, setdateRange] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  // Chef Pending Order notification
  const [pendingOrderCount, setPendingOrderCount] = useState(null);
  const [upcomingOrdersCount, setUpcomingOrdersCount] = useState({
    accepted: null,
    preparing: null,
    delivering: null,
    canceled: null,
    delivered: null,
  });
  const { authToken, userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      try {
        const res = await handleGetAllDishes(authToken);
        setDishes(res);
      } catch (error) {
        console.error("Error while fetching dishes \n", error);
      }
    })();
    (async () => {
      try {
        // const res = await handleGetTransaction(
        const res = await handlePostTransaction(
          authToken,
          userInfo?.id
          // dateRange.startDate,
          // dateRange.endDate
        );

        let data = res.reports.original.reports
        ? res.reports.original.reports
        : res.reports.original.data;
      var transformedOrders = data.flatMap((order) =>
        order.reports.map((report) => ({
          ...order,
          ...report,
          reports: undefined, // Remove the nested "reports" array
        }))
      );

        // here we can get sum for transaction for sales stats
        const calculatedSales = transformedOrders.reduce(
          (acc, item) => {
            acc.total_amount += item.total_amount;
            acc.service_charges += item.service_charges;
            acc.commission_received += item.commission_received;
            acc.net_delivery_charges += item.net_delivery_charges;
            acc.sale_amount += item.sale_amount;
            return acc;
          },
          {
            total_amount: 0,
            service_charges: 0,
            commission_received: 0,
            net_delivery_charges: 0,
            sale_amount: 0,
          }
        );
        console.log(calculatedSales);
        setSales(calculatedSales);

        // setDishes(res);
      } catch (error) {
        console.error("Error while fetching dishes \n", error);
      }
    })();

    // get data for upcoming orders
    const fetchOrders = async () => {
      try {
        const ordersRetrieved = await handleGetOrders(authToken, {
          chef_id: userInfo.id,
        });

        // ordersRetrieved.forEach((order) => {
        //   if (upcomingOrdersCount.hasOwnProperty(order.status)) {
        //     upcomingOrdersCount[order.status]++;
        //   }
        // });
        // console.log(upcomingOrdersCount);
        // setUpcomingOrdersCount(upcomingOrdersCount);

        // Make a copy of the initial state to work with
        const updatedOrdersCount = { ...upcomingOrdersCount };

        // Initialize counts to 0 if not already set
        Object.keys(updatedOrdersCount).forEach((key) => {
          if (updatedOrdersCount[key] === null) {
            updatedOrdersCount[key] = 0;
          }
        });

        // Count orders based on their status
        ordersRetrieved.forEach((order) => {
          if (updatedOrdersCount.hasOwnProperty(order.status)) {
            updatedOrdersCount[order.status]++;
          }
        });

        // Ensure if the count is zero, we set it to zero
        Object.keys(updatedOrdersCount).forEach((key) => {
          if (updatedOrdersCount[key] === 0) {
            updatedOrdersCount[key] = 0;
          }
        });

        // Update the state with the new counts
        setUpcomingOrdersCount(updatedOrdersCount);
        // console.log(updatedOrdersCount);
      } catch (error) {
        console.log("Error While Fetching Orders \n", error);
      } finally {
      }
    };
    fetchOrders();

    // for pending order check in after 5 minutes
    let intervalId;

    const fetchPendingOrderCount = async () => {
      try {
        const pendingOrderResponse = await handleGetPendingOrdersForChef(
          userInfo.id,
          authToken
        );
        setPendingOrderCount(pendingOrderResponse);
        console.log("response of pending order ", pendingOrderResponse);
      } catch (error) {
        console.error("Error while fetching Pending orders \n", error);
      } finally {
      }
    };
    if (userInfo.is_chef === 1 || userInfo.is_admin === 1) {
      fetchPendingOrderCount(); // Call it immediately on component mount if needed.

      intervalId = setInterval(() => {
        fetchPendingOrderCount();
      }, 300000); // 5 minutes in milliseconds
    }
  }, [authToken]);

  const isSalesEmpty = Object.keys(sales).length === 0;

  const handleImageClick = (dish) => {
      navigate("/shef/my-menu", { state: dish }); // Pass dish data in state
  };

  return (
    <div className="">
      <div className="">
        <Header />
        <div className="container mx-auto">
          <div className="grid grid-cols-12 gap-4 p-5 mt-5">
            <div className="col-span-12">
              <div className="rounded-xl p-5 border border-borderClr shadow-sm bg-white">
                {/* Header Section */}
                <div className="flex justify-between items-center gap-x-2 mb-6">
                  <div className="flex items-center gap-3">
                    <div className="bg-grayBg p-3 rounded-lg inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="32"
                        height="32"
                        fill="rgba(0,0,0,1)"
                      >
                        <path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM8 13V15H6V13H8ZM13 13V15H11V13H13ZM18 13V15H16V13H18ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path>
                      </svg>
                    </div>
                    <h2 className="sm:text-xl text-lg leading-tight font-semibold uppercase mb-0">
                      Your Orders Overview
                    </h2>
                  </div>
                  <NavLink to="/shef/order" className="hover:text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="32"
                      height="32"
                      fill="rgba(0,0,0,1)"
                    >
                      <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                    </svg>
                  </NavLink>
                </div>

                {/* Upcoming Orders Section */}
                <div className="mb-8">
                  <h6 className="text-2xl font-bold text-center text-gray-800 mb-4">
                    Upcoming Orders
                  </h6>
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-5 text-center">
                    {upcomingOrdersCount?.accepted === null
                      ? Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <div
                              key={index}
                              className="p-4 border border-gray-300 rounded-lg animate-pulse"
                            >
                              <div className="h-6 bg-gray-200 rounded-md mb-2"></div>
                              <div className="h-8 w-16 bg-gray-200 rounded-md mx-auto"></div>
                            </div>
                          ))
                      : [
                          {
                            label: "Accepted Orders",
                            count: upcomingOrdersCount?.accepted,
                          },
                          {
                            label: "Cancelled Orders",
                            count: upcomingOrdersCount?.canceled,
                          },
                          {
                            label: "Preparing Orders",
                            count: upcomingOrdersCount?.preparing,
                          },
                          {
                            label: "Delivering Orders",
                            count: upcomingOrdersCount?.delivering,
                          },
                          {
                            label: "Delivered Orders",
                            count: upcomingOrdersCount?.delivered,
                          },
                        ].map((item, index) => (
                          <div
                            key={index}
                            className="p-4 border border-gray-300 rounded-lg"
                          >
                            <h6 className="text-lg font-semibold text-gray-700">
                              {item.label}
                            </h6>
                            <span className="bg-primaryLight px-3 py-1 rounded-md text-secondary text-lg font-medium">
                              {item.count || 0}
                            </span>
                          </div>
                        ))}
                  </div>
                </div>

                {/* Divider for Visual Separation */}
                <hr className="my-6 border-gray-300" />

                {/* Pending Orders Section */}
                <div>
                  <h6 className="text-2xl font-bold text-center text-gray-800 mb-4">
                    Pending Orders
                  </h6>
                  <div className="p-4 border border-gray-300 rounded-lg text-center">
                    {pendingOrderCount === null ? (
                      // Loader when data is being fetched
                      <div className="animate-pulse">
                        <div className="h-6 bg-gray-200 rounded-md mb-2 w-20 mx-auto"></div>
                        <div className="h-8 bg-gray-200 rounded-md w-16 mx-auto"></div>
                      </div>
                    ) : (
                      // Content when data is available
                      <>
                        <h6 className="text-lg font-semibold text-gray-700">
                          Count
                        </h6>
                        <span className="bg-primaryLight px-3 py-1 rounded-md text-secondary text-lg font-medium">
                          {pendingOrderCount || 0}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:col-span-12 col-span-12">
              <div className="rounded-xl p-5 border border-borderClr h-full">
                <div className="flex justify-between items-center gap-x-2">
                  <div className="flex items-center gap-3">
                    <div className="bg-grayBg p-3 rounded-lg inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="32"
                        height="32"
                        fill="rgba(0,0,0,1)"
                      >
                        <path d="M8 1.5C8 0.947715 7.55228 0.5 7 0.5C6.44772 0.5 6 0.947715 6 1.5V2.5C6 2.50686 6.00042 2.51285 6.00081 2.51843C6.00385 2.56193 6.00516 2.58063 5.79289 2.79289L5.77277 2.81298C5.50599 3.07912 5 3.58391 5 4.5V5.5C5 6.05228 5.44772 6.5 6 6.5C6.55228 6.5 7 6.05228 7 5.5V4.5C7 4.49314 6.99958 4.48715 6.99919 4.48157C6.99615 4.43807 6.99484 4.41937 7.20711 4.20711L7.22723 4.18702C7.49401 3.92088 8 3.41609 8 2.5V1.5ZM19 1.5C19 0.947715 18.5523 0.5 18 0.5C17.4477 0.5 17 0.947715 17 1.5V2.5C17 2.50686 17.0004 2.51285 17.0008 2.51843C17.0038 2.56193 17.0052 2.58063 16.7929 2.79289L16.7728 2.81298C16.506 3.07912 16 3.58391 16 4.5V5.5C16 6.05228 16.4477 6.5 17 6.5C17.5523 6.5 18 6.05228 18 5.5V4.5C18 4.49314 17.9996 4.48715 17.9992 4.48157C17.9962 4.43807 17.9948 4.41937 18.2071 4.20711L18.2272 4.18702C18.494 3.92088 19 3.41609 19 2.5V1.5ZM12.5 0.5C13.0523 0.5 13.5 0.947715 13.5 1.5V2.5C13.5 3.41609 12.994 3.92088 12.7272 4.18702L12.7071 4.20711C12.4948 4.41937 12.4962 4.43807 12.4992 4.48157C12.4996 4.48715 12.5 4.49314 12.5 4.5V5.5C12.5 6.05228 12.0523 6.5 11.5 6.5C10.9477 6.5 10.5 6.05228 10.5 5.5V4.5C10.5 3.58391 11.006 3.07912 11.2728 2.81298L11.2929 2.79289C11.5052 2.58063 11.5038 2.56193 11.5008 2.51843C11.5004 2.51285 11.5 2.50686 11.5 2.5V1.5C11.5 0.947715 11.9477 0.5 12.5 0.5ZM4 10H20C20 14.4183 16.4183 18 12 18C7.58172 18 4 14.4183 4 10ZM3 8C2.44772 8 2 8.44771 2 9V10C2 14.1006 4.46819 17.6248 8 19.1679V20C8 20.5523 8.44772 21 9 21H15C15.5523 21 16 20.5523 16 20V19.1679C19.5318 17.6248 22 14.1006 22 10V9C22 8.44772 21.5523 8 21 8H3Z"></path>
                      </svg>
                    </div>
                    <h2 className="sm:text-xl text-lg leading-tight font-semibold uppercase mb-0">
                      Menu
                    </h2>
                  </div>
                </div>

                <div className="flex flex-wrap gap-4 mt-4">
                  {/* Check if dishes are still loading or empty */}
                  {dishes.length === 0 ? (
                    // Skeleton Loader while loading
                    Array(4)
                      .fill(0)
                      .map((_, index) => (
                        <div key={index} className="w-1/2 md:w-1/5 p-2">
                          {/* Skeleton loader for image and text */}
                          <div className="bg-gray-200 animate-pulse h-[150px] rounded-lg">
                            <div className="h-[100px] bg-gray-300 rounded-md mb-2"></div>{" "}
                            {/* Image skeleton */}
                            <div className="h-6 bg-gray-300 rounded-md w-3/4 mx-auto"></div>{" "}
                            {/* Text skeleton */}
                          </div>
                        </div>
                      ))
                  ) : // Display the dishes if data is available
                  dishes.length === 0 ? (
                    <div className="text-center w-full">
                      <span>No menu found</span>
                    </div>
                  ) : (
                    dishes.map((dish, index) => (
                      <div key={index} className="text-center">
                        <img
                          src={
                            dish.logo && isValidURL(dish.logo)
                              ? dish.logo
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/330px-No-Image-Placeholder.svg.png?20200912122019"
                          }
                          alt="Dish"
                          className="img-fluid border w-[110px] h-[100px] object-cover rounded-md cursor-pointer"
                          onClick={() => handleImageClick(dish)} // Add onClick here
                        />
                        <h4 className="text-[14px] mt-2">{dish.name}</h4>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            {/* Wallet - temporary commented */}
            {/* <div className='md:col-span-6 col-span-12'>
                            <div className='rounded-xl p-5 border border-borderClr h-full'>
                                <div className='flex justify-between items-center gap-x-2'>
                                    <div className='flex items-center gap-3'>
                                        <div className='bg-grayBg p-3 rounded-lg inline-block'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(0,0,0,1)">
                                                <path d="M18.0049 6.99979H21.0049C21.5572 6.99979 22.0049 7.4475 22.0049 7.99979V19.9998C22.0049 20.5521 21.5572 20.9998 21.0049 20.9998H3.00488C2.4526 20.9998 2.00488 20.5521 2.00488 19.9998V3.99979C2.00488 3.4475 2.4526 2.99979 3.00488 2.99979H18.0049V6.99979ZM4.00488 8.99979V18.9998H20.0049V8.99979H4.00488ZM4.00488 4.99979V6.99979H16.0049V4.99979H4.00488ZM15.0049 12.9998H18.0049V14.9998H15.0049V12.9998Z"></path>
                                            </svg>
                                        </div>
                                        <h2 className='sm:text-xl text-lg leading-tight font-semibold uppercase mb-0'>Wallet</h2>
                                    </div>
                                    <NavLink to="/shef/sales-statment">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="rgba(0,0,0,1)">
                                            <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                                        </svg>
                                    </NavLink>
                                </div>
                                <div className='mt-5 text-center'>
                                    <h4 className='text-5xl font-bold mb-0'>0<span className='text-base'>Rs.</span></h4>
                                    <h5 className='sm:text-lg text-base font-semibold leading-tight mt-2 mb-0' >WALLET BALANCE</h5>
                                </div>
                            </div>
                        </div> */}
            <div className="md:col-span-6 col-span-12">
              <NavLink to="/shef/profile">
                <div className="rounded-xl p-5 border border-borderClr h-full">
                  <div className="flex justify-between items-center gap-x-2">
                    <div className="bg-grayBg p-3 rounded-lg inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="32"
                        height="32"
                        fill="rgba(0,0,0,1)"
                      >
                        <path d="M21.0082 3C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM20 5H4V19H20V5ZM18 15V17H6V15H18ZM12 7V13H6V7H12ZM18 11V13H14V11H18ZM10 9H8V11H10V9ZM18 7V9H14V7H18Z"></path>
                      </svg>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="32"
                      height="32"
                      fill="rgba(0,0,0,1)"
                    >
                      <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                    </svg>
                  </div>
                  <h2 className="sm:text-xl text-lg leading-tight font-semibold uppercase mt-5 mb-0">
                    Your Profile
                  </h2>
                  <p className="mb-0 font-medium">Update your Profile!</p>
                </div>
              </NavLink>
            </div>
            <div className="md:col-span-6 col-span-12">
              <NavLink to="/shef/order-review">
                <div className="rounded-xl p-5 border border-borderClr h-full">
                  <div className="flex justify-between items-center gap-x-2">
                    <div className="bg-grayBg p-3 rounded-lg inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="32"
                        height="32"
                        fill="rgba(0,0,0,1)"
                      >
                        <path d="M21 6.75736L19 8.75736V4H10V9H5V20H19V17.2426L21 15.2426V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9.00319 2H19.9978C20.5513 2 21 2.45531 21 2.9918V6.75736ZM21.7782 8.80761L23.1924 10.2218L15.4142 18L13.9979 17.9979L14 16.5858L21.7782 8.80761Z"></path>
                      </svg>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="32"
                      height="32"
                      fill="rgba(0,0,0,1)"
                    >
                      <path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path>
                    </svg>
                  </div>
                  <h2 className="sm:text-xl text-lg leading-tight font-semibold uppercase mt-5 mb-0">
                    Your Order Review
                  </h2>
                  <p className="mb-0">See your Order Review!</p>
                </div>
              </NavLink>
            </div>

            <div className="md:col-span-12 col-span-12">
              <NavLink to="/shef/sales-statment" className="block">
                <div className="rounded-xl p-5 border border-borderClr shadow-sm bg-white hover:shadow-md transition-shadow duration-200 h-full">
                  {/* Header Section */}
                  <div className="flex items-center gap-x-4 mb-6">
                    <div className="bg-grayBg p-3 rounded-lg inline-block">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="32"
                        height="32"
                        fill="rgba(0,0,0,1)"
                      >
                        <rect
                          x="4"
                          y="10"
                          width="4"
                          height="10"
                          fill="rgba(0,0,0,1)"
                        ></rect>
                        <rect
                          x="10"
                          y="6"
                          width="4"
                          height="14"
                          fill="rgba(0,0,0,1)"
                        ></rect>
                        <rect
                          x="16"
                          y="2"
                          width="4"
                          height="18"
                          fill="rgba(0,0,0,1)"
                        ></rect>
                        <path
                          d="M14.121 10.707L11.5 8.086L11.5 14L9.5 14L9.5 8.086L6.879 10.707L5.465 9.293L10.5 4.257L15.535 9.293L14.121 10.707Z"
                          fill="rgba(0,0,0,1)"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <h2 className="sm:text-xl text-lg leading-tight font-semibold uppercase mb-1">
                        Your Sales
                      </h2>
                      <p className="text-sm text-gray-600">
                        Check your sales summary
                      </p>
                    </div>
                  </div>

                  {/* Sales Metrics */}
                  <div className="grid grid-cols-2 md:grid-cols-5 gap-5 text-center">
                    {isSalesEmpty
                      ? // Skeleton loaders while sales data is empty
                        [1, 2, 3, 4, 5].map((_, index) => (
                          <div
                            key={index}
                            className="p-4 border border-gray-300 rounded-lg animate-pulse"
                          >
                            <div className="h-6 bg-gray-200 rounded-md mb-4 w-3/4 mx-auto"></div>
                            <div className="h-8 bg-gray-200 rounded-md mb-2 w-1/2 mx-auto"></div>
                          </div>
                        ))
                      : // Actual content after sales data is fetched
                        [
                          { label: "Total Sales", value: sales?.sale_amount },
                          { label: "Total Amount", value: sales?.total_amount },
                          {
                            label: "Delivery Charges",
                            value: sales?.net_delivery_charges,
                          },
                          {
                            label: "Commission",
                            value: sales?.commission_received,
                          },
                          {
                            label: "Service Charges",
                            value: sales?.service_charges,
                          },
                        ].map((item, index) => (
                          <div
                            key={index}
                            className="p-4 border border-gray-300 rounded-lg"
                          >
                            <h6 className="text-lg font-semibold text-gray-700">
                              {item.label}
                            </h6>
                            <span className="bg-primaryLight px-3 py-1 rounded-md text-secondary text-lg font-medium">
                              {item.value || 0}
                            </span>
                          </div>
                        ))}
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <BeforeFooter />
    </div>
  );
};
export default Dashboard;
