import React, { useEffect, useState } from "react";
import "../assets/css/main-style.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleUserSignUp } from "../auth/Auth";
import { toast } from "react-toastify";
import Header from "../components/Header";
import InputMask from "react-input-mask";
import BeforeFooter from "../components/homeWidget/BeforeFooter";

const SignUp = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    phone: "",
    first_name: "",
    last_name: "",
  });

  const { userInfo } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // phone validation error
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Input field - OnChnage
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "last_name") {
      if (credentials.phone === "") {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      }
    }
    if (name === "phone") {
      const phoneRegex = /^03\d{9}$/;
      if (!phoneRegex.test(value)) {
        setError("Mobile number must be in the format 03XXXXXXXXX");
      } else {
        setError("");
      }
      // Update the credentials with the formatted phone number
      setCredentials({
        ...credentials,
        [name]: value,
      });
    } else if (name === "email") {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!emailRegex.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
      setCredentials({
        ...credentials,
        [name]: value,
      });
    } else {
      setCredentials({
        ...credentials,
        [name]: value,
      });
    }
  };

  // const validateEmail = (email) => {
  //   const regex =
  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|edu|gov|mil|co|info|io|pk)$/;
  //   return regex.test(email);
  // };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  // Form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailError && !error) {
      setIsLoading(true);

      try {
        e.preventDefault();
        const res = await handleUserSignUp(credentials);
        // if (res.email || res.success) {
        toast.success(typeof(res.message)==="string"? res.message: res.message.original.message  || "Register Successfully");
        setIsLoading(false);

        navigate("/login");
        // }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (userInfo) navigate("/");
  }, [userInfo, navigate]);

  const backgroundStyle = {
    backgroundImage: 'url("./media/frontend/img/banner/collection-1.jpg")',
  };
  return (
    <>
      <Header />
      <div className="position-relative h-[97vh] md:px-2 px-4">
        <div className="lg:w-2/3 w-full mx-auto h-full flex items-center my-auto py-5">
          <div className="authForm">
            <div className="grid grid-cols-12">
              <div className="md:col-span-6 col-span-12 md:order-1 order-2">
                <div className="form-container md:p-12 p-6 text-center">
                  <h1 className="genHead text-center mb-4">Sign up</h1>
                  <div className="w-[40px] h-[2px] bg-primary mx-auto mb-6"></div>
                  <form action="#" onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <input
                        value={credentials.email}
                        onChange={handleChange}
                        name="email"
                        type="email"
                        placeholder="john@example.com"
                        required
                      />
                      {/* Error warning */}
                      {emailError && (
                        <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                          {emailError}
                        </div>
                      )}
                    </div>
                    <input
                      className="mb-3"
                      value={credentials.first_name}
                      onChange={handleChange}
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      required
                    />
                    <input
                      className="mb-3"
                      value={credentials.last_name}
                      onChange={handleChange}
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      required
                    />
                    <div className="form-group mb-4">
                      <InputMask
                        mask="03999999999" // Fixed +92, and allow the user to enter 10 digits after it
                        value={credentials.phone}
                        onChange={handleChange}
                        className="your-input-class" // Your custom class here
                        placeholder="032xxxxxxxxx"
                        required
                        maskChar={"X"}
                        alwaysShowMask={true}
                      >
                        {(inputProps) => (
                          <input
                            {...inputProps}
                            type="text"
                            name="phone"
                            required
                          />
                        )}
                      </InputMask>
                      {/* Error warning */}
                      {error && (
                        <div className="bg-[#ff1c1c31] mt-1 text-red-900 py-1 px-1 text-[12px] font-semibold">
                          {error}
                        </div>
                      )}
                    </div>
                    <input
                      className="mb-3"
                      minLength={8}
                      value={credentials.password}
                      onChange={handleChange}
                      name="password"
                      type="password"
                      placeholder="Password"
                      required
                    />
                    <div className="term">
                      <input className="mb-3" type="checkbox" required />
                      <p>
                        By selecting "Sign up" you agree to Shef's&nbsp;
                        <Link to="/terms-of-services ">Terms of Service</Link>
                        ,&nbsp;
                        <Link to="/privacy-policy">Privacy Policy</Link>, and to
                        receive text messages
                      </p>
                    </div>
                    <div className="forgotTxt">
                      Already have an account? &nbsp;
                      <Link className="font-semibold" to="/login">
                        Sign in
                      </Link>
                    </div>
                    <button
                      disabled={error || emailError || isLoading}
                      className="mt-3 fillBtn disabled:cursor-not-allowed disabled:opacity-60"
                      type=""
                    >
                      {isLoading ? (
                        <div className="flex items-center">
                          <svg
                            className="w-5 h-5 mr-2 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                            ></path>
                          </svg>
                          Loading...
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                </div>
              </div>
              <div className="md:col-span-6 col-span-12 md:order-2 order-1">
                <div
                  className="authImgSide lg:py-0 py-24"
                  style={backgroundStyle}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BeforeFooter />
    </>
  );
};

export default SignUp;
