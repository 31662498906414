import React, { useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TopBanner from "../components/becomeChefWidgets/TopBanner";
import WhatIsShef from "../components/becomeChefWidgets/WhatIsShef";
import WhyBecomeShef from "../components/becomeChefWidgets/WhyBecomeShef";
import HowToSell from "../components/becomeChefWidgets/HowToSell";
import ReadyToStart from "../components/becomeChefWidgets/ReadyToStart";
import BeforeFooter from "../components/homeWidget/BeforeFooter";

export const BecomeChef = () => {
  const headerRef = useRef(null);

  const scrollToTop = () => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div ref={headerRef}>
        <Header />
      </div>
      <TopBanner />
      <WhatIsShef />
      <WhyBecomeShef />
      <HowToSell />
      <ReadyToStart onButtonClick={scrollToTop} />
      <BeforeFooter />
    </>
  );
};
export default BecomeChef;
